<script setup lang="ts">
const route = useRoute()

const isFooterVisible = computed(() => !route.meta.noFooter)
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <RAppHeader />

    <main class="min-h-[400px] w-full overflow-hidden">
      <slot />
    </main>

    <ClientOnly>
      <RAppFooter v-if="isFooterVisible" />

      <div>
        <RFloatingWhatsappButton />
        <RFloatingShareOptionsButton v-if="route.meta.shareable" />
      </div>
    </ClientOnly>
  </div>
</template>
