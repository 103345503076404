<script lang="ts" setup>
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()
const normalizeRoute = (route: string) => route.replace(/\//gim, '')
const isRouteActive = (path: string) => normalizeRoute(path) === normalizeRoute(route.path)

const handleClick = (path: string) => {
  router.push(path)
}

const links = computed(() => {
  const salesperson = route.params.salesperson
  const aboutPath = localePath({ name: salesperson ? 'salesperson-about' : 'about' })
  const productsPath = localePath({ name: salesperson ? 'salesperson-index' : 'index' })

  return [
    {
      id: 'products',
      name: t('routes.products'),
      path: productsPath,
      isActive: isRouteActive(productsPath),
    },
    { id: 'about', name: t('routes.about'), path: aboutPath, isActive: isRouteActive(aboutPath) },
  ]
})
</script>

<template>
  <div class="header-links flex items-center justify-between gap-4">
    <button
      v-for="link in links"
      :key="link.id"
      class="button h-9 text-sm"
      :class="[
        'menu-share-button rounded-full border-1 border-solid px-5 font-medium leading-[0] transition-colors',
        { active: link.isActive },
      ]"
      @click="handleClick(link.path)"
    >
      {{ link.name }}
    </button>
  </div>
</template>

<style scoped>
.button {
  &:hover,
  &.active {
    background: white;
    color: var(--color-header);
  }
}
</style>
