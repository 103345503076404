<script lang="ts" setup>
const { t } = useI18n()
const itemStore = useItemStore()
const { send } = useWhatsappMessage()

const redirect = () => {
  if (itemStore.currentItem?.id) {
    useItemRedirect(itemStore.currentItem)
    return
  }

  send(t('pages.productIndex.whatsappMessage'))
}
</script>

<template>
  <button
    class="fixed bottom-4 right-4 z-20 h-14 w-14 rounded-full bg-whatsapp p-[10px] text-white shadow-top-md"
    @click="redirect"
  >
    <RIcon name="mdi:whatsapp" size="38" />
  </button>
</template>
