<script lang="ts" setup>
const { t } = useI18n()
const cartStore = useCartStore()
</script>

<template>
  <div>
    <RBaseDrawer
      v-model:show="cartStore.isOpen"
      v-bind="$attrs"
      placement="right"
      class="max-w-[390px] !rounded-l-2xl !rounded-r-none"
      with-backdrop
      :title="t('components.cartDrawer.title')"
    >
      <div v-if="cartStore.isEmpty" class="mt-36 flex items-center justify-center">
        <RCartEmptyState variant="button" @cta-click="cartStore.close" />
      </div>
      <div v-else class="max-h-full overflow-auto pb-44">
        <RCartItems />

        <ClientOnly>
          <RCartFooter
            ref="footerRef"
            is-mobile
            class="cart-drawer fixed bottom-0 left-0 right-0 z-[55] w-full overflow-hidden !rounded-bl-xl bg-white p-4 shadow-top-sm"
          />
        </ClientOnly>
      </div>
    </RBaseDrawer>
  </div>
</template>
