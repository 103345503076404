<script setup lang="ts">
const props = defineProps<{
  show?: boolean
  disable?: boolean
}>()

const emit = defineEmits(['update:show'])

const handleToggle = () => {
  emit('update:show', !props.show)
}

const clickOutside = () => emit('update:show', false)

onBeforeMount(() => document.addEventListener('click', clickOutside))

onUnmounted(() => document.removeEventListener('click', clickOutside))
</script>

<template>
  <div class="popover relative" :class="{ open: props.show }">
    <template v-if="props.disable">
      <slot name="trigger" />
      <slot />
    </template>

    <template v-else>
      <RTransition name="slide-down-fade">
        <span v-if="props.show" class="arrow" />
      </RTransition>
      <button @click.stop="handleToggle">
        <slot name="trigger" />
      </button>

      <RTransition name="slide-down-fade">
        <div
          v-if="props.show"
          class="opacity-1 absolute -right-4 top-[66px] text-dusty-gray xl:-right-10"
          @click.stop
        >
          <slot />
        </div>
      </RTransition>
    </template>
  </div>
</template>

<style scoped>
.popover {
  .arrow {
    position: absolute;
    bottom: -26px;
    right: 50%;
    transform: translateX(50%);
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 0 12px 16px 12px;
    border-color: transparent transparent white transparent;
  }
}
</style>
