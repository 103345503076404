<script setup lang="ts">
import { useScreenSize } from '~/composables/useScreenSize'

const props = defineProps<{
  salespersonClass?: string
}>()

const globalStore = useGlobalStore()
const { isTabletPortraitDown } = useScreenSize()
</script>

<template>
  <ClientOnly>
    <NuxtLink
      class="justify-left flex items-center gap-3 text-base font-medium text-white sm:gap-4"
      v-bind="$attrs"
      to="/"
    >
      <div
        class="relative h-8 sm:h-10"
        :class="{
          'w-[52px] lg:w-[68px]': globalStore.store.salesperson,
          'w-8 lg:w-10': !globalStore.store.salesperson,
        }"
      >
        <img
          alt=""
          class="absolute bottom-0 left-0 top-0 aspect-square h-8 rounded-full border-white sm:h-10"
          :src="
            optimizedImage(globalStore.store.profileImageUri || '/images/empty-logo.png', 'thumb')
          "
        />

        <RSalespersonImage
          v-if="globalStore.store.salesperson"
          class="absolute bottom-0 left-[18px] top-0 h-8 w-8 !text-[18px] sm:h-10 sm:w-10 lg:left-[26px]"
          :class="props.salespersonClass"
          :salesperson="globalStore.store.salesperson"
        />
      </div>

      <div v-if="isTabletPortraitDown && globalStore.store.salesperson">
        <div class="text-base font-semibold leading-5">{{ globalStore.store.name }}</div>
        <div class="text-sm leading-5">{{ globalStore.store.salesperson.name }}</div>
      </div>
      <div v-else>
        {{ globalStore.pageTitle }}
      </div>
    </NuxtLink>
  </ClientOnly>
</template>
